import React, { FC } from 'react'
import cx from 'classnames'

import ProductCard, { ProductCardProps } from '../../ProductCard'
import { ActionButtonProps } from '../../ActionButton'
import useMediaQuery from '../../../hooks/useMediaQuery'
import BestProductsSlider from '../../BestProducts/BestProductsSlider'

import * as SC from './styled'

export type BlockCentralOffersCardsProps = {
  className?: string
  title: string
  items: Array<ProductCardProps>
  button: ActionButtonProps
}

const BlockCentralOffersCards: FC<BlockCentralOffersCardsProps> = ({
  className,
  title,
  items,
  button,
}) => {
  const matches = useMediaQuery('(min-width: 768px)')

  return (
    <SC.BlockCentralOffersCards
      className={cx('BlockCentralOffersCards', className)}
    >
      <SC.Title>{title}</SC.Title>
      {!matches && <BestProductsSlider items={items} />}
      {matches && (
        <SC.CardList>
          {items.map((item, index) => (
            <SC.CardListItem key={index}>
              <ProductCard {...item} />
            </SC.CardListItem>
          ))}
        </SC.CardList>
      )}
      <SC.Button {...button} />
    </SC.BlockCentralOffersCards>
  )
}

export default BlockCentralOffersCards
