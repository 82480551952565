import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BlockProps } from '../props'
import SectionWrapper from '../../../components/SectionWrapper'
import BlockCentralOffersCards, {
  BlockCentralOffersCardsProps,
} from '../../../components/blocks/BlockCentralOffersCards'
import { ActionButtonVariant } from '../../../components/ActionButton/styled'
import { ImageTransformer } from '../../Transformers/ImageTransformer'
import { ActionButtonTransformer } from '../../Transformers/LinkTransformer'
import Router from '../../../routes/Router'
import routes from '../../../routes/definitions'

import { OffersBlockProps } from './props'

const OffersBlock: FC<BlockProps<OffersBlockProps>> = ({ data }) => {
  const { t } = useTranslation()
  const props: BlockCentralOffersCardsProps = useMemo(
    () => ({
      title: data?.title,
      items: data?.offres?.data?.map((offer) => ({
        category: offer?.attributes?.categories?.data[0]?.attributes?.name,
        title: offer?.attributes.name,
        description: offer?.attributes.shortDescription,
        image: ImageTransformer(offer?.attributes?.image?.data),
        offerButton: {
          text: t('see_offer_button_text'),
          href: Router.getRouteUrl(routes.offer, {
            slug: offer?.attributes?.slug,
          }),
          variant: ActionButtonVariant.secondary,
        },
      })),
      button: {
        ...ActionButtonTransformer(data.link),
        variant: ActionButtonVariant.quaternary,
      },
    }),
    [t, data]
  )
  return (
    <SectionWrapper spacing="medium">
      <BlockCentralOffersCards {...props} />
    </SectionWrapper>
  )
}
export default OffersBlock
