import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import ActionButton from '../../ActionButton'

export const BlockCentralOffersCards = styled.div``

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.firaSans2XlBold};
  color: ${({ theme }) => theme.colors.bluePrimary};
  margin: 0 0 2rem 0;

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.firaSans3XlBold}
  }
`

export const CardList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
  justify-content: center;
`

export const CardListItem = styled.li`
  max-width: 30.5rem;
  span {
    height: 100%;
  }
`

export const Button = styled(ActionButton)`
  margin: 4rem auto 0 auto;
  display: block;
  width: max-content;
`
