import React, { FC, useState } from 'react'
import { SwiperSlide } from 'swiper/react'
import Swiper, { Navigation, Pagination } from 'swiper'

import ProductCard, { ProductCardProps } from '../../ProductCard'
import { Icons } from '../../Icon'

import * as SC from './styled'
import 'swiper/css'
import 'swiper/css/pagination'

export type BestProductsSliderProps = {
  className?: string
  items: ProductCardProps[]
}

const BestProductsSlider: FC<BestProductsSliderProps> = (props) => {
  const { className = '', items } = props
  const [swiperInstance, setSwiper] = useState<Swiper>()

  return (
    <SC.SwiperComponent
      className={className}
      modules={[Navigation, Pagination]}
      slidesPerView={1}
      pagination={{
        clickable: true,
        el: '.swiper-custom-pagination',
      }}
      onInit={(swiper) => setSwiper(swiper)}
    >
      {items.map((item, index) => (
        <SwiperSlide key={index}>{<ProductCard {...item} />}</SwiperSlide>
      ))}
      <SC.NavigationPaginationBlock>
        <SC.IconEl onClick={() => swiperInstance?.slidePrev()}>
          <SC.Arrow icon={Icons.chevronLeft} width={20} />
        </SC.IconEl>
        <SC.SwiperCustomPagination className="swiper-custom-pagination" />
        <SC.IconEl onClick={() => swiperInstance?.slideNext()}>
          <SC.Arrow icon={Icons.chevronRight} width={20} />
        </SC.IconEl>
      </SC.NavigationPaginationBlock>
    </SC.SwiperComponent>
  )
}

export default BestProductsSlider
