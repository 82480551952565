import { SLink } from '../../rest/types/api/Link'
import { ActionButtonProps } from '../../components/ActionButton'
import { Link } from '../../rest/types/Common'

export function ActionButtonTransformer(
  data?: SLink | Link
): ActionButtonProps | undefined {
  return data
    ? {
        variant: data?.variant,
        text: data?.text ?? '',
        label: data?.text ?? '',
        ariaLabel: data?.title ?? '',
        href: data?.href ?? '',
        target: data?.target ?? '_self',
      }
    : undefined
}
